<template>
  <div id="app">
    <div>
      <router-view v-slot="{ Component }">
        <transition name="">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App'
})
</script>
